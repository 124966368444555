import React from 'react'
import '../CSS/SuspenseContent.css'

function SuspenseContent(){
    return(
        <div class=" justify-center">
        <div class="loader "></div>
    </div>
    )
}

export default SuspenseContent